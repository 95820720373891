import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import AnimationWrapper from "../components/animationWrapper"
import Carousel from "../components/carousel"
import ContactSection from "../components/contactSection"
import Container from "../components/container"
import Header from "../components/header"
import InnerSection from "../components/innerSection"
import TextSection from "../components/textSection"
import Layout from "../components/layout"

import Logo from '../images/logo-header.png'
// import Photo1 from '../../static/img/czamaninek-1.jpg'
import Photo2 from '../../static/img/czamaninek-2.jpg'
import Photo3 from '../../static/img/czamaninek-3.jpg'
// import Photo4 from '../../static/img/czamaninek-4.jpg'
// import Photo5 from '../../static/img/czamaninek-5.jpg'
// import Photo5 from '../../static/img/czamaninek-5.jpg'
// import Photo6 from '../../static/img/czamaninek-6.jpg'
// import Photo7 from '../../static/img/czamaninek-7.jpg'
import Photo8 from '../../static/img/czamaninek-8.jpg'
// import Photo9 from '../../static/img/czamaninek-9.jpg'
import Photo10 from '../../static/img/czamaninek-10.jpg'
// import Photo11 from '../../static/img/czamaninek-11.jpg'
import Photo12 from '../../static/img/czamaninek-12.jpg'
import Photo13 from '../../static/img/czamaninek-13.jpg'
// import Photo14 from '../../static/img/czamaninek-14.jpg'
// import Photo15 from '../../static/img/czamaninek-15.jpg'
// import Photo16 from '../../static/img/czamaninek-16.jpg'
// import Photo17 from '../../static/img/czamaninek-17.jpg'
// import Photo18 from '../../static/img/czamaninek-18.jpg'
// import Photo19 from '../../static/img/czamaninek-19.jpg'

import "./o-firmie.css"

const AboutPage = ({data, location}) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      <AnimationWrapper>
        <Container className="o-firmie-logo-wrapper">
          <img src={Logo} alt="Czamaninek" />
        </Container>
      </AnimationWrapper>

      <Container>
        <TextSection className="o-firmie-description description-medium justify" data={data.page.frontmatter.text_sections[0]} />
      </Container>

      <Container>
        <AnimationWrapper className="youtube-wrapper">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/sYfzttHf4i4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen>
          </iframe>
        </AnimationWrapper>
      </Container>

      <Container>
        <TextSection className="o-firmie-description description-medium justify" data={data.page.frontmatter.text_sections[1]} />
      </Container>

      <InnerSection className="o-firmie-photos image-full">
        <div className="image-container">
            <img src={Photo2} alt="Czamaninek" />
        </div>
        <div className="image-container">
            <img src={Photo3} alt="Czamaninek" />
        </div>
      </InnerSection>

      <InnerSection className="o-firmie-2 image-full">
        <div className="description-container">
            <TextSection className="o-firmie-description description-medium align-left" data={data.page.frontmatter.text_sections[2]} />
        </div>
        <div className="image-container">
            <img src={Photo8} alt="Czamaninek" />
        </div>
      </InnerSection>

      <InnerSection className="o-firmie-4 image-full">
        <div className="image-container">
            <img src={Photo10} alt="Czamaninek" />
        </div>
        <div className="description-container">
            <TextSection className="o-firmie-description description-medium align-left" data={data.page.frontmatter.text_sections[3]} />
        </div>
      </InnerSection>

      <InnerSection className="o-firmie-photos image-full">
        <div className="image-container">
            <img src={Photo13} alt="Czamaninek" />
        </div>
        <div className="image-container">
            <img src={Photo12} alt="Czamaninek" />
        </div>
      </InnerSection>

      <section id="realizacje-carousel-section" className="page-section">
        <Carousel data={data.realizacjeCarousel.frontmatter.carousel_gallery} location="realizacje" />
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPage{
    page: mdx(frontmatter: { slug: { eq: "o-firmie" }, type: { eq: "page" } }) {
      frontmatter{
        title
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_sections{
          title
          title_visible
          icon{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          button_text
          button_style
          button_url
          text
        }
      }
    }
    realizacjeCarousel: mdx(
      frontmatter: {slug: {eq: "realizacje-carousel"}, type: {eq: "global"}}
    ){
      frontmatter{
        carousel_gallery{
          text_section{
            title
            title_visible
            subtitle
            text
            button_text
            button_url
            button_style
          }
          carousel_settings{
            slides_per_view
            transition_speed
            transition_delay
            object_fit
            rotate
            stretch
            depth
            scale
            slide_shadows
          }
          images{
            title
            title_visible
            description
            status
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default AboutPage
